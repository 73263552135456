//Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom types imports
import {
  OrderType,
  OrderStatusDescription,
  OrderStatusColor,
} from "../../../features/orders/types";

// Custom (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom queries imports
import { useOperationCenters } from "../../../features/operationCenters/useOperationCenters";
import { useSaleConditions } from "../../../features/saleConditions/useSaleConditions";
import { useBackorders } from "../../../features/backorders/useBackorders";
import { useSellers } from "../../../features/sellers/useSellers";
import { useBranches } from "../../../features/branches/useBranches";

// Custom helpers import
import { pad, formatNumber, formatDate } from "../../../helpers";

function DataOrderForm() {
  const methods = useFormContext<OrderType>();
  const {
    operationCenter,
    updatedAt,
    numDocto,
    status,
    createdAt,
    seller,
    saleCondition,
    backorder,
    branch,
    client,
  } = methods.watch();
  const operationCenters = useOperationCenters();
  const saleConditions = useSaleConditions();
  const backorders = useBackorders();
  const sellers = useSellers();
  const branches = useBranches(client?._id);

  const conditionsSaleFilteredByCollectionLevel =
    branch?._id && saleConditions?.data?.length
      ? saleConditions.data.filter(
          (saleCondition) =>
            saleCondition?.levelCollection <=
            branch?.saleCondition?.levelCollection
        )
      : [];

  return (
    <div className="w-full flex flex-col">
      {createdAt && updatedAt && (
        <div className="border-b w-full px-4 py-3 flex flex-wrap justify-between items-center">
          <div className="">
            <span className="text-gray-primary">
              Emitido: {formatDate(createdAt)}
            </span>
          </div>
          <div className="">
            <span>Última modificación: {formatDate(updatedAt)}</span>
          </div>
        </div>
      )}
      <div className="w-full px-4 py-3 grid grid-cols-2 sm:grid-cols-10 gap-x-4 gap-y-2">
        <CustomLabel
          label="Estado"
          id="status"
          required={false}
          className="text-sm col-span-1 sm:col-span-1"
          name="status"
        >
          <input
            disabled
            placeholder="Estado"
            style={{
              color: OrderStatusColor[status],
              fontWeight: 700,
            }}
            className="input-base"
            value={OrderStatusDescription[status]}
          />
        </CustomLabel>

        <CustomLabel
          label="ID ERP"
          id="numDocto"
          required={false}
          className="text-sm col-span-1 sm:col-span-1"
          name="numDocto"
        >
          <input
            disabled
            placeholder="ID ERP"
            className="input-base"
            value={numDocto ? `#${numDocto}` : "N/A"}
          />
        </CustomLabel>

        <CustomLabel
          label="Fecha de Entrega"
          id="deliverDate"
          required={true}
          className="text-sm col-span-1 sm:col-span-2"
          name="deliverDate"
        >
          <input
            disabled={status !== "pending"}
            type="date"
            placeholder="Fecha de entrega"
            className={`input-base ${
              methods.formState.errors.deliverDate ? "input-error" : ""
            } ${
              !methods.formState.errors.deliverDate &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("deliverDate", {
              required: "Este campo es requerido",
            })}
          />
        </CustomLabel>

        <CustomLabel
          label="Centro de operación"
          id="operationCenter"
          required={true}
          className="text-sm col-span-1 sm:col-span-2"
          name="operationCenter"
        >
          {status === "pending" ? (
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  operationCenters.isLoading ||
                  !operationCenters.data ||
                  status !== "pending"
                }
                className={`input-base pr-[40px] overflow-hidden appearance-none ${
                  methods.formState.errors.operationCenter ? "input-error" : ""
                } ${
                  !methods.formState.errors.operationCenter &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                value={operationCenter?._id}
                onChange={(e) => {
                  if (operationCenters.data) {
                    const operationCenter = operationCenters.data.find(
                      (operationCenter) =>
                        operationCenter._id === e.target.value
                    );
                    if (operationCenter) {
                      methods.setValue("operationCenter", operationCenter);
                    }
                  }
                }}
              >
                <option value="">Seleccionar</option>
                {operationCenters.data?.map((operationCenter) => (
                  <option value={operationCenter._id} key={operationCenter._id}>
                    {pad(operationCenter.idOperationCenter, 3)}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          ) : (
            <input
              disabled
              placeholder="Centro de operación"
              className="input-base"
              value={pad(operationCenter?.idOperationCenter, 3)}
            />
          )}
        </CustomLabel>

        <CustomLabel
          label="Vendedor"
          id="seller"
          required={true}
          className="text-sm col-span-2 sm:col-span-4"
          name="seller"
        >
          {status === "pending" ? (
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  !branch?._id ||
                  sellers.isLoading ||
                  !sellers.data ||
                  status !== "pending"
                }
                className={`input-base pr-[40px] overflow-hidden appearance-none ${
                  methods.formState.errors.seller ? "input-error" : ""
                } ${
                  !methods.formState.errors.seller &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                value={seller?._id}
                onChange={(e) => {
                  if (sellers.data) {
                    const sellerSelected = sellers.data.find(
                      (s) => s._id === e.target.value
                    );
                    if (sellerSelected) {
                      methods.setValue("seller", sellerSelected);
                    }
                  }
                }}
              >
                <option value="">Seleccionar vendedor</option>
                {sellers.data?.map((seller) => (
                  <option value={seller._id} key={seller._id}>
                    {seller.idSeller} {seller.name}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          ) : (
            <input
              disabled
              placeholder="Vendedor"
              className="input-base"
              value={`${seller.idSeller} ${seller.name}`}
            />
          )}
        </CustomLabel>

        <CustomLabel
          label="NIT del Cliente"
          id="nit"
          required={false}
          className="text-sm col-span-2"
        >
          <input
            disabled
            type="text"
            placeholder="NIT del Cliente"
            className="input-base"
            value={client?.nit ? formatNumber(Number(client?.nit)) : undefined}
          />
        </CustomLabel>

        <CustomLabel
          label="Nombre del Cliente"
          id="name"
          required={false}
          className="text-sm col-span-2 sm:col-span-4"
        >
          <input
            disabled
            placeholder="Nombre del Cliente"
            className="input-base"
            value={client?.name}
          />
        </CustomLabel>

        <CustomLabel
          label="Sucursal"
          id="branch"
          required={true}
          className="text-sm col-span-2 sm:col-span-4"
          name="branch"
        >
          {status === "pending" ? (
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  branches.isLoading || !branches.data || status !== "pending"
                }
                className={`input-base pr-[40px] overflow-hidden appearance-none ${
                  methods.formState.errors.branch ? "input-error" : ""
                } ${
                  !methods.formState.errors.branch &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                value={branch?._id}
                onChange={(e) => {
                  if (branches.data) {
                    const bra = branches.data.find(
                      (branch) => branch._id === e.target.value
                    );
                    if (bra) {
                      methods.setValue("branch", bra);
                      methods.setValue("backorder", bra.backorder);
                      methods.setValue("saleCondition", bra.saleCondition);
                      methods.setValue("seller", bra.seller);
                    }
                  }
                }}
              >
                <option value="">Seleccionar Sucursal</option>
                {branches.data?.map((bra) => (
                  <option value={bra._id} key={bra._id}>
                    {pad(bra.idBranch, 3)}{" "}
                    {bra.description}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          ) : (
            <input
              disabled
              placeholder="Sucursal"
              className="input-base"
              value={`#${branch?.idBranch} ${branch?.description}`}
            />
          )}
        </CustomLabel>

        <CustomLabel
          label="Backorder"
          id="backorder._id"
          required={true}
          className="text-sm col-span-2 sm:col-span-4"
          name="backorder"
        >
          {status === "pending" ? (
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  !branch?._id ||
                  backorders.isLoading ||
                  !backorders.data ||
                  status !== "pending"
                }
                className={`input-base pr-[40px] overflow-hidden appearance-none ${
                  methods.formState.errors.backorder ? "input-error" : ""
                } ${
                  !methods.formState.errors.backorder &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                value={backorder?._id}
                onChange={(e) => {
                  if (backorders.data) {
                    const backorder = backorders.data.find(
                      (backorder) => backorder._id === e.target.value
                    );
                    if (backorder) {
                      methods.setValue("backorder", backorder);
                    }
                  }
                }}
              >
                <option value="">Seleccionar backorder</option>
                {backorders.data?.map((backorder) => (
                  <option value={backorder._id} key={backorder._id}>
                    {backorder.name}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          ) : (
            <input
              disabled
              placeholder="Backorder"
              className="input-base"
              value={`#${backorder?.idBackorder} ${backorder?.name}`}
            />
          )}
        </CustomLabel>

        <CustomLabel
          label="Condición de Venta"
          id="saleCondition"
          required={true}
          className="text-sm col-span-2 sm:col-span-3"
          name="saleCondition"
        >
          {status === "pending" ? (
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  !branch?._id ||
                  saleConditions.isLoading ||
                  !saleConditions.data ||
                  status !== "pending"
                }
                className={`input-base pr-[40px] overflow-hidden appearance-none ${
                  methods.formState.errors.saleCondition ? "input-error" : ""
                } ${
                  !methods.formState.errors.saleCondition &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                value={saleCondition?._id}
                onChange={(e) => {
                  if (saleConditions.data) {
                    const saleCondition = saleConditions.data.find(
                      (saleCondition) => saleCondition._id === e.target.value
                    );
                    if (saleCondition) {
                      methods.setValue("saleCondition", saleCondition);
                    }
                  }
                }}
              >
                <option value="">Seleccionar condición de venta</option>
                {conditionsSaleFilteredByCollectionLevel?.map(
                  (saleCondition) => (
                    <option value={saleCondition._id} key={saleCondition._id}>
                      {saleCondition.name}
                    </option>
                  )
                )}
              </select>
              <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          ) : (
            <input
              disabled
              placeholder="Condicion de Venta"
              className="input-base"
              value={`#${saleCondition?.idSaleCondition} ${saleCondition?.name}`}
            />
          )}
        </CustomLabel>

        <CustomLabel
          label="Departamento"
          id="dpto"
          required={false}
          className="text-sm col-span-2 sm:col-span-3"
        >
          <input
            disabled
            placeholder="Departamento"
            className="input-base"
            value={branch?.dpto}
          />
        </CustomLabel>

        <CustomLabel
          label="Ciudad"
          id="city"
          required={false}
          className="text-sm col-span-2 sm:col-span-3"
        >
          <input
            disabled
            placeholder="Ciudad"
            className="input-base"
            value={branch?.city}
          />
        </CustomLabel>

        <CustomLabel
          label="Barrio"
          id="neighborhood"
          required={false}
          className="text-sm col-span-2 sm:col-span-3"
        >
          <input
            disabled
            placeholder="Barrio"
            className="input-base"
            value={branch?.neighborhood}
          />
        </CustomLabel>

        <CustomLabel
          label="Dirección"
          id="adress"
          required={false}
          className="text-sm col-span-2 sm:col-span-4"
        >
          <input
            disabled
            placeholder="Dirección"
            className="input-base"
            value={branch?.adress}
          />
        </CustomLabel>

        <CustomLabel
          label="Observaciones"
          id="note"
          required={false}
          className="text-sm col-span-2 sm:col-span-10"
          name="note"
        >
          <textarea
            rows={2}
            autoCorrect="off"
            className={`w-full input-base text-gray-primary ${
              methods.formState.errors.note ? "input-error" : ""
            } ${
              !methods.formState.errors.note && methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("note", {
              required: "Este campo es requerido",
            })}
          ></textarea>
        </CustomLabel>
      </div>
    </div>
  );
}
export default DataOrderForm;
