// Custom components imports
import CustomLabel from "../shared/fields/CustomLabel";
import CurrencyInput from "react-currency-input-field";

// Custom types import
import { ProductType } from "../../features/products/types";
import { SimplifiedItems } from "../../features/items/types";

// Custom helpers imports
import {
  formatNumber,
  getQuantityWarehouse,
  getQuantitySimplifiedItem,
  setQuantity,
} from "../../helpers";

interface Props {
  product: ProductType;
  simplifiedItems: SimplifiedItems[];
  setSimplifiedItems: React.Dispatch<React.SetStateAction<SimplifiedItems[]>>;
  idWarehouse: string;
}

export default function QuantitiesOfExtensionsInSpecificWarehouse({
  product,
  simplifiedItems,
  setSimplifiedItems,
  idWarehouse,
}: Props) {
  const quantitiesByWarehouse =
    getQuantityWarehouse(simplifiedItems, idWarehouse) || 0;
  return (
    <div className="w-full col-span-2 grid gap-y-1">
      {product.extensions.map((extension) => (
        <CustomLabel
          key={extension._id + idWarehouse}
          label={extension.name}
          id={`${extension._id + idWarehouse}-quantity`}
          required={false}
          className="text-sm"
        >
          <CurrencyInput
            decimalSeparator=","
            groupSeparator="."
            decimalsLimit={0}
            allowNegativeValue={false}
            allowDecimals={false}
            placeholder="Ingresar cantidades"
            className="input-base"
            onValueChange={(value) => {
              if (extension._id) {
                setQuantity(
                  simplifiedItems,
                  setSimplifiedItems,
                  Number(value),
                  extension.idExtension.toString(),
                  idWarehouse
                );
              }
            }}
            value={getQuantitySimplifiedItem(
              simplifiedItems,
              extension._id,
              idWarehouse
            )?.toString()}
          />
        </CustomLabel>
      ))}
      <div className="pb-3 flex justify-between">
        <p className="font-bold">Total</p>
        <p className="font-bold">{formatNumber(quantitiesByWarehouse)}</p>
      </div>
    </div>
  );
}
