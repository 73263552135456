// Custom components imports
import CustomLabel from "../shared/fields/CustomLabel";
import CurrencyInput from "react-currency-input-field";

// Custom types imports
import { ProductType } from "../../features/products/types";
import { SimplifiedItems } from "../../features/items/types";
import { RAABType } from "../../features/rraab/types";

// Custom helpers imports
import {
  getQuantities,
  formatNumber,
  getQuantitiesExtension,
  setQuantityAutomaticDistribution,
} from "../../helpers";

interface Props {
  product: ProductType;
  simplifiedItems: SimplifiedItems[];
  setSimplifiedItems: React.Dispatch<React.SetStateAction<SimplifiedItems[]>>;
  raab: RAABType;
}

export default function AutomaticDistributionQuantitiesWithExtensions({
  product,
  simplifiedItems,
  setSimplifiedItems,
  raab,
}: Props) {
  const quantities = getQuantities(simplifiedItems);

  return (
    <div className="w-full col-span-2 grid gap-y-1">
      {product.extensions.map((extension) => (
        <CustomLabel
          key={extension._id}
          label={extension.name}
          id={`${extension._id}-quantity`}
          required={false}
          className="text-sm"
        >
          <CurrencyInput
            decimalSeparator=","
            groupSeparator="."
            decimalsLimit={0}
            allowNegativeValue={false}
            allowDecimals={false}
            placeholder={`Ingresar cantidades`}
            className="input-base"
            onValueChange={(value) => {
              setQuantityAutomaticDistribution(
                setSimplifiedItems,
                Number(value),
                product,
                raab,
                extension?.idExtension?.toString() || ""
              );
            }}
            value={getQuantitiesExtension(
              simplifiedItems,
              extension._id
            )?.toString()}
          />
        </CustomLabel>
      ))}
      <div className="pb-3 flex justify-between">
        <p className="font-bold">Total</p>
        <p className="font-bold">{formatNumber(quantities || 0)}</p>
      </div>
    </div>
  );
}
