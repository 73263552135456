// ** React query Imports
import { useInfiniteQuery } from "@tanstack/react-query";

// ** Config apisauce
import api from "../../app/api";

// ** Helpers imports
import { timeout } from "../../helpers";

// ** Types Imports
import { FiltersOrderType, PaginationOrderType } from "./types";
import { SIZE } from "./types";

type PageParamType = {
  page: number;
  size: number;
};

const defaultPageParam = {
  page: 1,
  size: SIZE,
};

export function useOrdersInfinite(params: FiltersOrderType) {
  async function getOrders(
    pageParam: PageParamType,
    params: FiltersOrderType
  ): Promise<PaginationOrderType> {
    await timeout(500);
    const response = await api.post<PaginationOrderType>(
      "orders/paginated",
      {},
      {
        params: {
          page: pageParam.page,
          size: pageParam.size,
          ...(params.status && {
            status: params.status,
          }),
          ...(params.search && {
            search: params.search,
          }),
          ...(params.idOperationCenter && {
            idOperationCenter: params.idOperationCenter,
          }),
        },
      }
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useInfiniteQuery({
    queryKey: ["OrdersInfinite", ...Object.values(params)],
    queryFn: ({ pageParam = defaultPageParam }) => getOrders(pageParam, params),

    getNextPageParam: (lastPage, allPages) => {
      const totalItems = lastPage.totalItems;
      const totalPages = Math.ceil(totalItems / SIZE);
      const penultimatePage = totalPages - 1;
      const items = allPages.flatMap((page) => page.items) || [];
      // const offset = totalItems % SIZE || SIZE;
      // const isPenultimatePage = allPages.length === penultimatePage;
      if (items.length === totalItems) {
        return undefined;
      } else {
        return {
          page: allPages.length + 1,
          size: SIZE,
        };
      }
    },
  });
}
