// React imports
import { useState } from "react";

// Custom types imports
import { CustomerType } from "../../../features/customers/types";

// Custom components imports
import SearchInput from "../../shared/fields/SearchInput";
import InfiniteListAssignableCustomers from "../../infinite_lists/InfiniteListAssignableCustomers";

// Custom queries imports;
import { useCustomersInfinite } from "../../../features/customers/useCustomersInfinite";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAdd: (data: CustomerType) => void;
}

function DrawerAssignClient({ open, setOpen, onAdd }: Props) {
  const [customerSelected, setCustomerSelected] = useState<
    CustomerType | undefined
  >(undefined);

  const closeDrawer = () => {
    setOpen(false);
    setCustomerSelected(undefined);
  };

  const [search, setSearch] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const customers = useCustomersInfinite({ search: searchFilter });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div id="InfiniteListAssignableCustomersContainer" className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
          <button type="button" onClick={() => closeDrawer()}>
            <i className="mr-4 text-xl bi bi-arrow-left"></i>
          </button>{" "}
          <b className="font-medium text-lg">Asignar Cliente</b>
          <button
            disabled={!customerSelected}
            className="ml-auto button-primary inline-block w-[160px] text-sm"
            type="button"
            onClick={() => {
              if (customerSelected) {
                onAdd(customerSelected);
                closeDrawer();
              }
            }}
          >
            Guardar
          </button>
        </div>
        <div
          className="w-full px-4 py-3 flex justify-between items-center"
        >
          <SearchInput
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            onStopTyping={(value) => setSearchFilter(value)}
          />
        </div>
        <InfiniteListAssignableCustomers
          customers={customers}
          customerSelected={customerSelected}
          setCustomerSelected={setCustomerSelected}
        />
      </div>
    </div>
  );
}

export default DrawerAssignClient;
