// Custom types imports
import { WarehouseType } from "../../features/warehouses/types";
import { SimplifiedItems } from "../../features/items/types";
import { ProductType } from "../../features/products/types";

// Custom helpers imports
import {
  setQuantity,
  getQuantityWarehouse,
  formatNumber,
  getQuantities,
} from "../../helpers";

// Custom components imports
import CustomLabel from "../shared/fields/CustomLabel";
import CurrencyInput from "react-currency-input-field";

interface Props {
  product: ProductType;
  warehouses: WarehouseType[];
  simplifiedItems: SimplifiedItems[];
  setSimplifiedItems: React.Dispatch<React.SetStateAction<SimplifiedItems[]>>;
}

export default function ManageProductQuantitiesWithoutExtensions({
  product,
  warehouses,
  simplifiedItems,
  setSimplifiedItems,
}: Props) {
  const quantities = getQuantities(simplifiedItems);

  return (
    <div className="col-span-2 grid gap-y-1">
      <div className="w-full max-w-full">
        {warehouses.map((warehouse) => (
          <CustomLabel
            key={warehouse._id}
            label={warehouse.idWarehouse}
            id={`${warehouse.idWarehouse}-quantity`}
            required={false}
            className="text-sm"
          >
            <CurrencyInput
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={0}
              allowNegativeValue={false}
              allowDecimals={false}
              placeholder={`Ingresar cantidades en ${warehouse.idWarehouse}`}
              className="input-base mb-1"
              onValueChange={(value) => {
                if (product.extensions[0].idExtension) {
                  setQuantity(
                    simplifiedItems,
                    setSimplifiedItems,
                    Number(value),
                    product.extensions[0].idExtension.toString(),
                    warehouse.idWarehouse
                  );
                }
              }}
              value={getQuantityWarehouse(
                simplifiedItems,
                warehouse.idWarehouse
              )?.toString()}
            />
          </CustomLabel>
        ))}
      </div>

      <div className="pb-1 flex justify-between">
        <b>Total</b>
        <b>{formatNumber(quantities || 0)}</b>
      </div>
    </div>
  );
}
