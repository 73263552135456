function HeaderGridItem() {
    return (
      <div className="hidden md:grid bg-gray-100 text-gray-700 divide-x border-t w-full h-[40px] grid-cols-[40px_60px_3fr_70px_70px_80px_110px_3fr_3fr_3fr_50px]">
      <div className="px-2 flex items-center">
        <b className="text-xs">Cod</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">ID Ext</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">Descripción</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">BD</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">Empaque</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">Cantidad</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">Precio</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">IVA</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">Subtotal</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-xs">Total</b>
      </div>
      <div className="px-2 flex items-center"></div>
    </div>
    );
  }
  export default HeaderGridItem;
  