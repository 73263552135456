// Types imports
import { ItemType } from "../../../features/items/types";

// Custom helpers imports
import { formatPrice, formatNumber } from "../../../helpers";

interface Props {
  item: ItemType;
  onSelect: (item: ItemType) => void;
}

function RowItem({ item, onSelect }: Props) {
  return (
    <div className="divide-x border-t w-full h-[50px] grid grid-cols-[40px_60px_3fr_70px_70px_80px_110px_3fr_3fr_3fr_50px]">
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">
          {item.extension.product.codProduct}
        </span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">{item.extension.idExtension}</span>
      </div>
      <div className="px-2 flex justify-center items-start flex-col overflow-hidden">
        <p
          className="w-full font-bold text-xs truncate cursor-pointer"
          title={item.extension.product.description}
        >
          {item.extension.product.description}
        </p>
        <span className="text-xs truncate">{item.extension.name}</span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">{item.warehouse.idWarehouse}</span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">{item.extension.product.unitInventory}</span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">{formatNumber(item.quantity)}</span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">{formatPrice(item.price)}</span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">{formatPrice(item.iva)}</span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">{formatPrice(item.subtotal)}</span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="text-xs truncate">{formatPrice(item.total)}</span>
      </div>
      <div
        className="px-2 flex justify-center items-center cursor-pointer"
        onClick={() => onSelect(item)}
      >
        <i className="text-red-primary text-lg bi bi-pencil-square"></i>
      </div>
    </div>
  );
}
export default RowItem;
