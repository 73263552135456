// React imports
import { useEffect } from "react";

// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";
import DiscriminationOfTotals from "../../shared/DiscriminationOfTotals";
import CurrencyInput from "react-currency-input-field";

// Custom types imports
import { ItemType } from "../../../features/items/types";

// Custom helpers imports
import { getTotals } from "../../../helpers";

// Hooks & Components (react-hook-form) imports
import { useFormContext } from "react-hook-form";

function EditItemForm() {
  const methods = useFormContext<ItemType>();
  const product = methods.watch("extension").product;
  const quantity = methods.watch("quantity");

  useEffect(() => {
    const { total, subtotal, iva } = getTotals(
      product.price,
      quantity,
      product.taxRate
    );
    methods.setValue("iva", iva);
    methods.setValue("subtotal", subtotal);
    methods.setValue("total", total);
    methods.setValue("price", product.price);
  }, [product, quantity, methods]);

  return (
    <div className="w-full px-4 py-3 grid grid-cols-4 gap-x-4 gap-y-2">
      <CustomLabel
        label="Codigo"
        id="codProduct"
        required={false}
        className="text-sm col-span-1"
      >
        <input
          disabled
          placeholder="Codigo"
          className="input-base"
          value={methods.watch("extension.product.codProduct")}
        />
      </CustomLabel>
      <CustomLabel
        label="Nombre"
        id="name"
        required={false}
        className="text-sm col-span-3"
      >
        <input
          disabled
          placeholder="Nombre"
          className="input-base"
          value={methods.watch("extension.product.description")}
        />
      </CustomLabel>

      <CustomLabel
        label="ID Extensión"
        id="idExtension"
        required={false}
        className="text-sm col-span-1"
      >
        <input
          disabled
          placeholder="ID Extensión"
          className="input-base"
          value={methods.watch("extension").idExtension}
        />
      </CustomLabel>
      <CustomLabel
        label="Extension"
        id="extension"
        required={false}
        className="text-sm col-span-3"
      >
        <input
          disabled
          placeholder="Extension"
          className="input-base"
          value={methods.watch("extension").name}
        />
      </CustomLabel>

      <CustomLabel
        label="Bodega"
        id="warehouse"
        required={false}
        className="text-sm col-span-2"
      >
        <input
          disabled
          placeholder="Bodega"
          className="input-base"
          value={methods.watch("warehouse").idWarehouse}
        />
      </CustomLabel>

      <CustomLabel
        label="Und Empaque"
        id="unitPackaging"
        required={false}
        className="text-sm col-span-1"
      >
        <input
          disabled
          placeholder="Unidad Empaque"
          className="input-base"
          value={methods.watch("extension").product.unitPackaging}
        />
      </CustomLabel>

      <CustomLabel
        label="Und Inventario"
        id="unitInventory"
        required={false}
        className="text-sm col-span-1"
      >
        <input
          disabled
          placeholder="Und Inventario"
          className="input-base"
          value={methods.watch("extension").product.unitInventory}
        />
      </CustomLabel>

      <CustomLabel
        label="Cantidad"
        id="quantity"
        required={true}
        className="text-sm col-span-4"
        name="quantity"
      >
        <CurrencyInput
          decimalSeparator=","
          groupSeparator="."
          decimalsLimit={0}
          allowNegativeValue={false}
          allowDecimals={false}
          placeholder="Cantidad"
          value={methods.watch("quantity")}
          onValueChange={(value) =>
            methods.setValue("quantity", Number(value) || 0)
          }
          className={`input-base ${
            methods.formState.errors.quantity ? "input-error" : ""
          } ${
            !methods.formState.errors.quantity && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
        />
      </CustomLabel>
      <div className="col-span-4">
        <DiscriminationOfTotals
          taxRate={product.taxRate}
          price={product.price}
          iva={methods.watch("iva")}
          subtotal={methods.watch("subtotal")}
          total={methods.watch("total")}
        />
      </div>
    </div>
  );
}

export default EditItemForm;
