// Types imports
import { ItemType } from "../../../features/items/types";

// Custom helpers imports
import {
  formatPrice,
  formatNumber,
  getTotalsItems,
  getQuantitiesItems,
} from "../../../helpers";

interface Props {
  items: ItemType[];
}

function RowTotals({ items }: Props) {
  const quantity = getQuantitiesItems(items);
  const { total, subtotal, iva } = getTotalsItems(items);

  return (
    <>
      <div className="hidden md:grid bg-gray-100 divide-x  border-t w-full h-[40px] grid-cols-[40px_60px_3fr_70px_70px_80px_110px_3fr_3fr_3fr_50px]">
        <div className="px-1 flex justify-center items-start flex-col">
          <b className="text-xs truncate">Totales</b>
        </div>
        <div className="px-2 flex items-center"></div>
        <div className="px-2 flex items-center"></div>
        <div className="px-2 flex items-center"></div>
        <div className="px-2 flex items-center"></div>
        <div className="px-2 flex items-center overflow-hidden">
          <p className="w-full text-xs truncate font-bold cursor-pointer">
            {formatNumber(quantity)}
          </p>
        </div>

        <div className="px-2 flex items-center"></div>
        <div className="px-2 flex items-center overflow-hidden">
          <p className="w-full text-xs truncate font-bold cursor-pointer">
            {formatPrice(iva)}
          </p>
        </div>
        <div className="px-2 flex items-center overflow-hidden">
          <p className="w-full text-xs truncate font-bold cursor-pointer">
            {formatPrice(subtotal)}
          </p>
        </div>
        <div className="px-2 flex items-center overflow-hidden">
          <p className="w-full text-xs truncate font-bold cursor-pointer">
            {formatPrice(total)}
          </p>
        </div>
        <div className="px-2 flex items-center cursor-pointer"></div>
      </div>
      <div className="w-full block md:hidden">
        <div
          className="px-4 w-full flex justify-between pb-2 py-3"
          style={{ borderBottomColor: "#D0D0D0", borderBottomWidth: 0.5 }}
        >
          <span className="text-[#929292]">IVA</span>
          <span className="text-[#929292]">{formatPrice(iva)}</span>
        </div>
        <div
          className="px-4 w-full flex justify-between py-3"
          style={{ borderBottomColor: "#D0D0D0", borderBottomWidth: 0.5 }}
        >
          <span className="text-[#929292]">SubTotal</span>
          <span className="text-[#929292]">
            {formatPrice(subtotal)}
          </span>
        </div>
        <div className="px-4 w-full flex justify-between py-3">
          <span className="text-lg font-[800]">Total</span>
          <span className="text-lg font-[800]">{formatPrice(total)}</span>
        </div>
      </div>
    </>
  );
}
export default RowTotals;
