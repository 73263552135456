// Custom components imports
import DeleteItem from "./DeleteItem";
import EditItemForm from "./EditItemForm";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Custom types imports
import { ItemType } from "../../../features/items/types";
import { OrderStatusType } from "../../../features/orders/types";

interface Props {
  status: OrderStatusType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: ItemType;
  onDelete: () => void;
  onUpdate: (data: ItemType) => void;
}

function DrawerEditItem({
  status,
  open,
  setOpen,
  item,
  onDelete,
  onUpdate,
}: Props) {
  const methodsItem = useForm<ItemType>({
    defaultValues: item,
  });

  const closeDrawer = () => {
    methodsItem.reset();
    setOpen(false);
  };

  const onSubmit = methodsItem.handleSubmit((data) => {
    onUpdate(data);
    closeDrawer();
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[520px] h-full">
        <FormProvider {...methodsItem}>
          <form onSubmit={onSubmit}>
            <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="mr-4 text-xl bi bi-arrow-left"></i>
              </button>{" "}
              <p className="font-medium sm:text-base truncate">
                {item.extension.product.description}
              </p>
              {status === "pending" && (
                <button
                  className="ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                  type="submit"
                >
                  Guardar
                </button>
              )}
            </div>
            {status === "pending" && (
              <DeleteItem
                onDelete={() => {
                  confirmChange(
                    "¿Estas seguro de eliminar este item?",
                    "Al eliminar este item, el cambio sera permanente",
                    () => {
                      onDelete();
                      closeDrawer();
                    }
                  );
                }}
              />
            )}
            <EditItemForm />
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default DrawerEditItem;
