// React imports
import { useState } from "react";

// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import HeaderGridItem from "./HeaderGridItem";
import RowItem from "./RowItem";
import RowTotals from "./RowTotals";
import EmptyContent from "../../shared/EmptyContent";
import SearchInput from "../../shared/fields/SearchInput";
import ItemCard from "../../cards/ItemCard";
import CustomLabel from "../../shared/fields/CustomLabel";

// custom querires imports
import { useWarehouses } from "../../../features/warehouses/useWarehouses";

// Custom helpers imports
import { formatNumber } from "../../../helpers";

//Custom types imports
import { ItemType } from "../../../features/items/types";

interface Props {
  items: ItemType[];
  onSelect: (item: ItemType, index: number) => void;
}

function ListItems({ items, onSelect }: Props) {
  const [search, setSearch] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [warehouse, setWarehouse] = useState<string>("");
  const warehouses = useWarehouses();

  const itemsFiltered = items
    .filter(
      (item) =>
        item.extension.product.codProduct
          .toUpperCase()
          .includes(searchFilter.toUpperCase()) ||
        item.extension.product.description
          .toUpperCase()
          .includes(searchFilter.toUpperCase()) ||
        item.extension.name
          .toUpperCase()
          .includes(searchFilter.toUpperCase()) ||
        item.extension.idExtension
          ?.toString()
          .toUpperCase()
          .includes(searchFilter.toUpperCase())
    )
    .filter((item) => item.warehouse._id.includes(warehouse));

  return (
    <ContentWithToggle title="Detalle de Pedido" isToogle={true}>
      <div className="divide-y">
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Número de Productos</span>
          <div className="w-[20%] text-end">
            <b>{formatNumber(itemsFiltered.length)}</b>
          </div>
        </div>

        <div className="grid gap-x-4 w-full grid-cols-3 px-4 py-3 items-end">
          <SearchInput
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            className="col-span-3 sm:col-span-2"
            onStopTyping={(value) => setSearchFilter(value)}
          />

          <CustomLabel
            label="Bodegas"
            id="warehouse"
            required={false}
            className="hidden sm:block text-sm !mb-0"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled={warehouses.isLoading || !warehouses.data}
                className="input-base pr-[40px] overflow-hidden appearance-none"
                value={warehouse}
                onChange={(e) => setWarehouse(e.target.value)}
              >
                <option value="">Todas las Bodegas</option>
                {warehouses.data?.map((warehouse) => (
                  <option value={warehouse._id} key={warehouse._id}>
                    {warehouse.idWarehouse} {warehouse.name}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>
        </div>

        {itemsFiltered.length === 0 ? (
          <div className="w-full border-y">
            <div className="w-full">
              <EmptyContent text="No hay items" />
            </div>
          </div>
        ) : (
          <>
            <HeaderGridItem />
            <div className="w-full block md:hidden divide-y">
              {itemsFiltered.map((item, index) => (
                <ItemCard
                  key={item._id}
                  item={item}
                  onSelect={(item) => onSelect(item, index)}
                />
              ))}
            </div>
            <div className="w-full hidden md:block">
              {itemsFiltered.map((item, index) => (
                <RowItem
                  item={item}
                  key={item._id}
                  onSelect={(item) => onSelect(item, index)}
                />
              ))}
            </div>
            <RowTotals items={itemsFiltered} />
          </>
        )}
      </div>
    </ContentWithToggle>
  );
}

export default ListItems;
